<template>
  <fieldset
    class="mt-3"
    :class="[
      `form-radio-group--${type}`,
      {
        'space-y-3': type === 'default',
        'grid rounded-md': type === 'small-stack',
        'grid-cols-2': type === 'small-stack' && options.length === 2,
        'grid-cols-3': type === 'small-stack' && options.length === 3,
      },
    ]"
  >
    <Radio
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :name="name"
      :value="option.value"
      :modelValue="modelValue"
      @update:modelValue="onChange"
    />
  </fieldset>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string | number;
    name: string;
    options: SelectOptions;
    type?: "default" | "small-stack";
  }>(),
  {
    type: "default",
  }
);

const emit = defineEmits(["update:modelValue"]);

const { autosave, submit } = injectStrict<ProvideForm>("form");

const onChange = async (value) => {
  emit("update:modelValue", value);

  if (autosave) {
    await submit();
  }
};
</script>

<style lang="postcss">
.form-radio-group {
  &--small-stack {
    input {
      @apply hidden;
    }

    label {
      @apply m-0 w-full px-4 py-3;
    }

    .ag-radio {
      @apply ring-1 ring-input hover:ring-input-hover;

      + .ag-radio {
        margin-left: 1px;
      }

      &:first-child {
        @apply rounded-l-md;
      }

      &:last-child {
        @apply rounded-r-md;
      }

      &--checked {
        @apply z-10 bg-primary-50 ring-primary-300 hover:ring-primary-300;
      }
    }
  }
}
</style>
