<template>
  <div class="ag-radio flex items-center" :class="{ 'ag-radio--checked': isChecked }">
    <input
      type="radio"
      :id="value"
      :name="name"
      :checked="isChecked"
      :value="value"
      class="form-radio h-4 w-4 border-input text-primary-500 focus:ring-primary-500"
      @change="emit('update:modelValue', $event.target.value)"
    />
    <label :for="value" class="ml-3 block text-sm">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: string | number;
  name: string;
  label?: string;
  value: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const isChecked = computed(() => props.modelValue === props.value);
</script>
